import { Injectable } from '@angular/core';

import { IOfficeData, IGroup, IOfficeUser } from '../../interfaces/office.interface';
import { HelperProvider } from '../helper.provider';
import { HttpClient } from '@angular/common/http';
import { IMainMenuItem } from '../../interfaces/mainMenuItem.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfficeHelperService {

  public officeData: IOfficeData;
  public localOfficeData: IOfficeData;

  constructor(
    private helperProvider: HelperProvider,
    private http: HttpClient
  ) { }


  /**
   * 
   * @param officeId 
   * get office data from server
   */
  getCurrentOfficeData(officeId: string): IOfficeData {
    return <IOfficeData>{};
  }

  /**
   * return office data from local storage
   */
  getLocalOfficeData(): Promise<IOfficeData> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        this.officeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        resolve(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
      } else {
        reject('No Office Doc Found. Please Make Sure you have some Office selected');
      }
    });
  }

  getOfficeApps(): Array<any> {
    return [];
  }

  /**
   * this is to get calendar id from the office doc...
   */
  getOfficeCalendarId(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.officeData) {
        if (this.officeData.apps && this.officeData.apps['Agenda'] && this.officeData.apps['Agenda']['id']) {
          resolve(this.officeData.apps['Agenda']['id']);
        } else {
          reject('No Calendar App found in the office Doc');
        }
      } else {
        reject('No Office Doc Found. Please Make Sure you have some Office selected');
      }
    });
  }

  getOfficeAddressBookId(): string {
    return '';
  }

  getOfficeGroups(): Array<IGroup> {
    return [];
  }

  getOfficeUsers(): Promise<Array<IOfficeUser>> {
    return new Promise((resolve, reject) => {
      this.getLocalOfficeData().then((officeData: IOfficeData) => {
        resolve((officeData && officeData.users) || []);
      }).catch(err => {
        reject(err);
      });
    });
  }

  getOfficeList(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.helperProvider.getRequest(`${environment.serverUrl}api/offices`)
          .then((mainMenu: Array<any>) => {
            resolve(mainMenu || []);
          }).catch(err => {
            console.log(err);
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  getOfficeMainMenu(): Promise<Array<IMainMenuItem>> {
    return new Promise((resolve, reject) => {
      try {
        this.getLocalOfficeData();
        const officeApps = this.getOfficeApps();
        if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
          this.helperProvider.getRequest(`${environment.serverUrl}api/offices/${this.officeData._id}/apps/${officeApps['mainMenu']['id']}/main-menu`)
            .then((mainMenu: Array<IMainMenuItem>) => {
              resolve(mainMenu || []);
            }).catch(err => {
              console.log(err);
              reject(err);
            });
        } else {
          reject('No main Menu found in the office doc');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

    /**
   * 
   * @param officeId 
   * get Office data
   */
  getOfficeData(officeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (officeId) {
          this.helperProvider.getRequest(`${environment.serverUrl}api/offices/${officeId}`)
          .then(resolve).catch(reject);
        } else {
          reject('No office Id provided. Please check configuration or consult admin about it');
        }
    });
  }
}
