import { Injectable } from '@angular/core';
// import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { Events } from '@ionic/angular';
import { HelperProvider } from './helper.provider';

export enum ConnectionStatusEnum {
    Online,
    Offline
}

@Injectable({
    providedIn: 'root'
  })
export class NetworkProvider {

  previousStatus;

  constructor(
    //   public network: Network,
            //   public eventCtrl: Events,
              private helperProvider: HelperProvider
              ) {

    this.previousStatus = ConnectionStatusEnum.Online;
    
  }

    public initializeNetworkEvents(): void {

        // this.network.onDisconnect().subscribe(() => {
            
        //     if (this.previousStatus === ConnectionStatusEnum.Online) {
        //         // this.eventCtrl.publish('network:offline');
        //     }
        //     this.previousStatus = ConnectionStatusEnum.Offline;
        // }, err => {
        //     alert(err);
        // });
        // this.network.onConnect().subscribe(() => {
                    
        //     if (this.previousStatus === ConnectionStatusEnum.Offline) {
        //         // this.eventCtrl.publish('network:online');
        //     }
        //     this.previousStatus = ConnectionStatusEnum.Online;
        // }, err => {
        //     alert(err);
        // });

    }

}
