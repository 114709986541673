export const locale = {
    lang: 'en',
    data: {
        basic: {
            save: 'Save',
            cancel: 'Cancel',
            done: 'Done',
            edit:  'Edit',
            close: 'Close',
            next: 'Next',
            version: 'Version',
            env: 'Env',
            search: 'Search',
            scan_qr_heading: 'Scan QR code',
            scan_qr_description: 'Fit the qrcode in the camera'
        },
        menu: {
            office: 'Office',
            office_list: 'Office List',
            issue_reporter: 'Issue Reporter',
            logout: 'Logout'
        },
        dashboard: {
            dashboard: 'Dashboard',
            change_selected_company: 'Change Selected Company',
            route_manager: 'Route Manager',
            logout: 'Logout',
            change_selected_car: 'Change Selected Car',
            generate_route: 'Generate Route',
            welcome_back: 'Welcome Back',
            action: 'Action',
            close: 'Close',
            yes: 'Yes',
            no: 'No',
            complete_pickup: 'Complete Pickup',
            send: 'Send',
            company_list: 'Company List',
            company_list_desc: 'Company List Desc',
            car_list: 'Car List',
            car_list_desc: 'Car List Desc',
            route_list: 'Route List',
            route_list_desc: 'Route List desc',
            pickup: 'Pickup',
            general: 'General',
            map: 'Map',
            open: 'Open',
            okay: 'Okay',
            action_success: 'Action Success',
            qty_taken: 'Quantity Taken',
            tank_empty: "Did you pumped out everything?",
            tank_empty_yes: "Tank is empty",
            tank_empty_no: "Tank is not empty",
            connector: "Connector",
            connector_yes: "Pumping throu connector",
            connector_no: "Pumping throu entry",
            open_google_map_current_point: 'Open Google Map To get This point',
            open_google_map_all_point: 'Google Map that shows all points',
            report_other_problem: 'Report other problem - Open text area - to put the problem',
            not_able_to_pickup: 'Not able to pickup, Move Pickup to last position in route',
            inform_owner: 'Inform owner That you are not able to pickup',
            open_map: 'Open Map',
            add_one_more_order: 'Add One more order to route',
            move_to_drop_point: 'Start Moving to drop point',
            drop_at_drop_point: 'Drop at Drop Point',
            report_issue: 'Report Issue',
            pickup_gen_desc: 'Pickup Description',
            done: 'Done',
            pickup_action_desc: 'Pickup Action Description',
            permission_denied: 'Location Permission Denied, Enable it to continue using app',
            ARE_YOUR_SURE_YOU_WANT_TO_TAKE_BACK_CAR: 'Are You sure you want to take car?',
            drop_point: 'Drop Point',
            order_list: "Order List",
            car_info: "Car Info",
            enter_detailed_reason: "Enter Reason",
            error: "Error",
            confirm: "Confirm",
            change_company_confirm: "Are you sure you want to change company?",
            change_car_confirm: "Are you sure you want to change car?",
            qty_required: "Qty Required",
            connector_required: "Qty Required",
            is_tank_empty_header: "Is Tank Empty?",
            is_tank_empty_message: "Is Tank Empty Message?",
            cancel: "Cancel",
            select_option: "Select Option",
            reason_sent: "Reason Sent",
            complete_drop: "Complete Drop"
        }
    }
};
