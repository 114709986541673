export const locale = {
    lang: 'pl',
    data: {
        basic: {
            save: 'Zapisz',
            cancel: 'Anuluj',
            done: 'Wyk.',
            edit:  'Edytuj',
            close: 'Zamknij',
            next: 'Dalej',
            version: 'Wersja',
            env: 'Env',
            search: 'Szukaj',
            scan_qr_heading: 'Skan QR code',
            scan_qr_description: 'Umieść QR code w kamerze'
        },
        menu: {
            office: 'Biuro',
            office_list: 'Lista biur',
            issue_reporter: 'Zgloszenia problemów',
            logout: 'Wyloguj'
        },
        auth: {
            login_heading: 'Login',
            email: 'Email',
            email_required: 'Email jest wymagany',
            password: 'Hasło',
            password_required: 'Hasło jest wymagane',
            login: 'Login',
        },
        dashboard: {
            dashboard: 'Start',
            change_selected_company: "Zmień wybraną firmę",
            change_selected_car: "Zmień auto",
            generate_route: "Generuj trasę",
            welcome_back: 'Witaj ponownie',
            action: "Akcje",
            close: "Zamknij",
            yes: "Tak",
            no: "Nie",
            logout: 'Wyloguj',
            complete_pickup: "Odebrano",
            send: "Wyślij",
            company_list: 'Lista firm',
            company_list_desc: 'Wybierz firmę, której autem chcesz jeździć',
            car_list: 'Lista aut',
            car_list_desc: 'Wybierz auto, którym chcesz jechać - uwaga auto w którym jest już inna osoba jest dla Ciebie niedostępne',
            route_list: 'Lista tras',
            route_list_desc: 'Wybierz trasę którą chcesz obsłużyć',
            pickup: "Odbiór",
            general: "Ogólne",
            map: "Mapa",
            open: "Otwórz",
            okay: 'Ok',
            action_success: "Sukces",
            qty_taken: "Odebrana ilość",
            tank_empty: "Czy zbiornik został wypompowany do dna?",
            tank_empty_yes: "Wypompowane wszystko",
            tank_empty_no: "Zbiornik częściowo zapełniony",
            connector: "Złączka",
            connector_yes: "Podłączenie przez złączkę",
            connector_no: "Podłączenie przez właz",
            open_google_map_current_point: "Pokaż trasę w mapie google",
            open_google_map_all_point: "Pokaż wszystkie punkty w mapie google",
            report_other_problem: "Zgłoś problem",
            not_able_to_pickup: "Brak możliwości odbioru, przesuń odbiór na koniec trasy",
            inform_owner: "Poinformuj klienta o braku możliwości odbioru",
            open_map: "Otwórz mapę",
            add_one_more_order: "Dodaj odbiór do zlecenia",
            move_to_drop_point: "Powrót do zlewni",
            drop_at_drop_point: "Potwierdz zrzut",
            report_issue: "Złoś problem",
            permission_denied: "Zezwól przeglądarce na korzystanie z twojej lokalizacji",
            ARE_YOUR_SURE_YOU_WANT_TO_TAKE_BACK_CAR: 'Czy jesteś pewien, że chcesz przejąć auto? - Przejmiesz je z aktualnym stanem trasy',
            route_manager: 'Manager tras',
            drop_point: 'Droga do zlewni',
            order_list: "Lista odbiorów",
            car_info: "Informacja o aucie",
            enter_detailed_reason: "Enter Reason",
            error: "Error",
            confirm: "Confirm",
            change_company_confirm: "Are you sure you want to change company?",
            change_car_confirm: "Are you sure you want to change car?",
            qty_required: "Qty Required",
            connector_required: "Qty Required",
            is_tank_empty_header: "Is Tank Empty?",
            is_tank_empty_message: "Is Tank Empty Message?",
            cancel: "Cancel",
            select_option: "Select Option",
            reason_sent: "Reason Sent",
            complete_drop: "Complete Drop"

        }
    }
};